import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IntroPage from './components/IntroPage';
import ProductSelection from './components/ProductSelection';
import WheelSpin from './components/WheelSpin';
import WinningPage from './components/WinningPage';
import AdminPanel from './components/admin/AdminPanel';
import AdminLogin from './components/admin/AdminLogin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/products" element={<ProductSelection />} />
        <Route path="/wheel-spin" element={<WheelSpin />} />
        <Route path="/winning" element={<WinningPage />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/*" element={<AdminPanel />} />
      </Routes>
    </Router>
  );
}

export default App;
