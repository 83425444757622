import React, { useEffect, useState, useRef } from 'react';
import logoSrc from '../assets/imgs/logoForWheel.png'; // Ensure this path is correct

const WheelComponent = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  primaryColor = 'black',
  contrastColor = 'white',
  buttonText = 'Spin',
  isOnlyOnce = true,
  size = 200,
  upDuration = 100,
  downDuration = 10000,
  fontFamily = 'proxima-nova',
  fontSize = '1em',
  outlineWidth = 10,
  spinTheWheel,
  resetSpin,
  clicked,
  canWheelStop,
}) => {
  const canWheelStopRef = useRef(canWheelStop);
  const decelerationStart = useRef(null);
  const bulbAnimationFrame = useRef(0);

  const logoImage = useRef(new Image());
  useEffect(() => {
    logoImage.current.src = logoSrc;
  }, []);

  // Update canWheelStopRef when canWheelStop changes
  useEffect(() => {
    canWheelStopRef.current = canWheelStop;
  }, [canWheelStop]);

  // Animation loop for bulbs
  useEffect(() => {
    let animationFrameId;

    const animate = (timestamp) => {
      bulbAnimationFrame.current = timestamp;
      draw();
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  // Handle spinTheWheel prop
  useEffect(() => {
    if (spinTheWheel) {
      console.log('spin clicked');
      spin(); // Call the spin function when spinTheWheel is true
      resetSpin(); // Reset the spinTheWheel state after spinning
    }
  }, [spinTheWheel, resetSpin]);

  // Handle clicked prop
  useEffect(() => {
    if (clicked) {
      clickToSpin();
    }
  }, [clicked]);

  // Constants for bulbs and bar
  const bulbRadius = 12; // Adjust as desired
  const barWidth = 25; // Thickness of the bar
  const extraPadding = 20; // Additional padding to prevent clipping

  // Use the size prop directly
  const maxRadius = size + bulbRadius + barWidth / 2 + extraPadding;
  const dimension = maxRadius * 2;
  const centerX = maxRadius;
  const centerY = maxRadius;

  const currentSegment = useRef('');
  const isStarted = useRef(false);
  const timerHandle = useRef(0);
  const spinStart = useRef(0);
  const frames = useRef(0);
  const [isFinished, setFinished] = useState(false);
  const timerDelay = segments.length;
  const angleCurrent = useRef(0);
  const angleDelta = useRef(0);
  const canvasContext = useRef(null);
  const maxSpeed = useRef(Math.PI / segments.length);
  const upTime = segments.length * upDuration;
  const downTime = segments.length * downDuration;

  const canvasRef = useRef(null);

  useEffect(() => {
    wheelInit();
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }, [size]); // Re-initialize when size changes

  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };

  const initCanvas = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      console.error('Canvas element not found');
      return;
    }

    const pixelRatio = window.devicePixelRatio || 1;
    canvas.width = dimension * pixelRatio;
    canvas.height = dimension * pixelRatio;
    canvas.style.width = `${dimension}px`;
    canvas.style.height = `${dimension}px`;
    canvasContext.current = canvas.getContext('2d');
    canvasContext.current.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  };

  const spin = () => {
    isStarted.current = true;
    if (timerHandle.current === 0) {
      spinStart.current = new Date().getTime();
      decelerationStart.current = null; // Reset deceleration start time

      // Introduce randomness in maxSpeed
      const minSpeed = Math.PI / segments.length; // Minimum speed
      const maxPossibleSpeed = Math.PI / (segments.length / 2); // Maximum speed
      maxSpeed.current =
        minSpeed + Math.random() * (maxPossibleSpeed - minSpeed);

      // Introduce randomness in initial angle
      angleCurrent.current = Math.random() * Math.PI * 2;

      frames.current = 0;
      timerHandle.current = window.setInterval(onTimerTick, timerDelay);
    }
  };

  const onTimerTick = () => {
    frames.current++;
    draw();
    const currentTime = new Date().getTime();
    const duration = currentTime - spinStart.current;
    let progress = 0;
    let finished = false;

    if (duration < upTime) {
      // Accelerate
      progress = duration / upTime;
      angleDelta.current =
        maxSpeed.current * Math.sin((progress * Math.PI) / 2);
    } else {
      // After the acceleration phase, check if we can decelerate
      if (canWheelStopRef.current) {
        // Begin deceleration
        if (decelerationStart.current === null) {
          decelerationStart.current = currentTime; // Set deceleration start time
        }
        const decelerationDuration = currentTime - decelerationStart.current;
        progress = decelerationDuration / downTime;
        angleDelta.current =
          maxSpeed.current * (1 - Math.sin((progress * Math.PI) / 2));
        if (progress >= 1) {
          finished = true;
          angleDelta.current = 0; // Stop the wheel
        }
      } else {
        // Continue spinning at max speed
        angleDelta.current = maxSpeed.current;
      }
    }

    angleCurrent.current += angleDelta.current;
    // Normalize the angle
    angleCurrent.current %= Math.PI * 2;

    if (finished) {
      setFinished(true);
      onFinished(currentSegment.current);
      clearInterval(timerHandle.current);
      timerHandle.current = 0;
    }
  };

  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key, lastAngle, angle) => {
    if (!canvasContext.current) {
      return false;
    }
    const ctx = canvasContext.current;
    const value = segments[key];
    ctx.save();
    // Set shadow properties for the segment
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)'; // Shadow color and opacity
    ctx.shadowBlur = 5; // Blur radius
    ctx.shadowOffsetX = 0; // Horizontal offset
    ctx.shadowOffsetY = 0; // Vertical offset

    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key % segColors.length];
    ctx.fill();
    ctx.stroke();

    ctx.restore(); // Restore the state to remove shadow effects for other drawings

    // Draw the text on the segment
    ctx.save(); // Save state again for text
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);

    ctx.fillStyle = 'black';
    ctx.font = `bold ${fontSize} ${fontFamily}`;
    ctx.fillText(value.substring(0, 18), size / 2 + 20, 0);
    ctx.restore();
  };

  const drawBulbBar = () => {
    if (!canvasContext.current) {
      return false;
    }
    const ctx = canvasContext.current;

    // Use the bulbRadius and barWidth defined at the component level
    const barRadius = size + bulbRadius + barWidth / 2;

    ctx.save();

    // Draw the bar (ring)
    ctx.beginPath();
    ctx.arc(centerX, centerY, barRadius, 0, 2 * Math.PI);
    ctx.closePath();

    // Set the bar's styles
    ctx.fillStyle = '#6a707b'; // Adjust as desired
    ctx.strokeStyle = '#C0C0C0'; // Adjust as desired
    ctx.lineWidth = barWidth;

    // Fill and stroke the bar
    ctx.fill(); // Use fill() if you want a solid bar
    ctx.stroke();

    ctx.restore();
  };

  const drawBulbs = () => {
    if (!canvasContext.current) {
      return false;
    }
    const ctx = canvasContext.current;
    const bulbCount = segments.length * 2; // Adjust as desired
    const bulbDistance = size + bulbRadius + barWidth / 2;

    ctx.save();

    // Glow effect
    ctx.shadowColor = 'rgba(255, 255, 0, 0.9)';
    ctx.shadowBlur = 20;

    // Calculate which bulbs are on
    const phase = (bulbAnimationFrame.current / 200) % bulbCount;

    for (let i = 0; i < bulbCount; i++) {
      const angle = (2 * Math.PI * i) / bulbCount + angleCurrent.current;
      const x = centerX + bulbDistance * Math.cos(angle);
      const y = centerY + bulbDistance * Math.sin(angle);

      ctx.beginPath();
      ctx.arc(x, y, bulbRadius, 0, 2 * Math.PI);
      ctx.closePath();

      // Determine if the bulb is on or off
      const isOn = (i + Math.floor(phase)) % bulbCount < bulbCount / 2;

      if (isOn) {
        // Bulb is on
        const bulbColor = '#FFD700'; // Golden color
        const gradient = ctx.createRadialGradient(x, y, 0, x, y, bulbRadius);
        gradient.addColorStop(0, '#FFFFFF'); // White center
        gradient.addColorStop(0.5, bulbColor); // Middle color
        gradient.addColorStop(1, '#000000'); // Black edge

        ctx.fillStyle = gradient;
      } else {
        // Bulb is off
        ctx.fillStyle = '#4B0082'; // Dark color for off bulbs
      }

      ctx.fill();
      ctx.strokeStyle = isOn ? '#FFD700' : '#4B0082';
      ctx.lineWidth = 2;
      ctx.stroke();
    }

    ctx.restore();
  };

  const drawWheel = () => {
    if (!canvasContext.current) {
      return false;
    }
    clear();

    // Draw the bulb bar
    drawBulbBar();

    // Draw the bulbs
    drawBulbs();

    const ctx = canvasContext.current;
    let lastAngle = angleCurrent.current;
    const len = segments.length;
    const PI2 = Math.PI * 2;
    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.font = `1em ${fontFamily}`;
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent.current;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw a center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 50, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = primaryColor;
    ctx.lineWidth = 10;
    ctx.strokeStyle = '#ef0707';
    ctx.fill();
    ctx.font = `bold 1em ${fontFamily}`;
    ctx.fillStyle = contrastColor;
    ctx.textAlign = 'center';
    ctx.fillText(buttonText, centerX, centerY + 3);

    const logoSize = 100; // Desired display size
    const logoDisplaySize = logoSize;
    const logoPosX = centerX - logoDisplaySize / 2;
    const logoPosY = centerY - logoDisplaySize / 2;

    if (logoImage.current.complete) {
      ctx.drawImage(
        logoImage.current,
        logoPosX,
        logoPosY,
        logoDisplaySize,
        logoDisplaySize
      );
    } else {
      logoImage.current.onload = () => {
        ctx.drawImage(
          logoImage.current,
          logoPosX,
          logoPosY,
          logoDisplaySize,
          logoDisplaySize
        );
      };
    }
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();

    ctx.lineWidth = outlineWidth;
    ctx.strokeStyle = primaryColor;
    ctx.stroke();
  };

  const drawNeedle = () => {
    if (!canvasContext.current) {
      return false;
    }
    const ctx = canvasContext.current;
    ctx.lineWidth = 5;
    ctx.strokeStyle = '#ef0907';
    ctx.fillStyle = '#ef0907';
    ctx.beginPath();
    ctx.moveTo(centerX + 20, centerY - 50);
    ctx.lineTo(centerX - 20, centerY - 50);
    ctx.lineTo(centerX, centerY - 70);
    ctx.closePath();
    ctx.fill();
    const change = angleCurrent.current + Math.PI / 2;
    let i =
      segments.length -
      Math.floor((change / (Math.PI * 2)) * segments.length) -
      1;
    if (i < 0) i = i + segments.length;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = primaryColor;
    ctx.font = `bold 15vh ${fontFamily}`;
    currentSegment.current = segments[i];
    // Uncomment the line below if you want to display the current segment
    // isStarted.current && ctx.fillText(currentSegment.current, centerX + 10, centerY + size + 50);
  };

  const clear = () => {
    if (!canvasContext.current) {
      return false;
    }
    const ctx = canvasContext.current;
    ctx.clearRect(0, 0, dimension, dimension);
  };

  const clickToSpin = () => {
    if (!isFinished) {
      spin();
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'center',
        cursor: isFinished ? 'not-allowed' : 'pointer',
      }}
    >
      <canvas
        ref={canvasRef}
        onClick={() => {
          if (!isFinished) {
            spin();
          }
        }}
        width={dimension}
        height={dimension}
        style={{
          pointerEvents: isFinished && isOnlyOnce ? 'none' : 'auto',
        }}
      />
    </div>
  );
};

export default WheelComponent;
