import React from "react";
import exitButton from "../assets/imgs/exitButton.png";
import { Link } from 'react-router-dom';
import "./styles/termsModal.css";
import pdfFile from "../assets/files/Takanon.pdf";

const TermsModal = ({setShowTerms, setWasTermsRead}) => {

const closeModal = () => {
    setShowTerms(false);
    setWasTermsRead(true);
}

    return(
        <div className="terms-modal-drop-shadow">
            <div className="terms-modal">
                <div className="terms-modal-header" style={{alignItems:'center'}}>
                    <h2 style={{direction:'rtl', textAlign:'center', width:'100%', fontSize:'6vh'}}>כללי ההגרלה:</h2>
                    <img src={exitButton} alt="Exit Button" style={{height:'5vh', marginLeft:'auto', cursor:'pointer'}} className="exit-button" onClick={(closeModal)} />
                </div>
                <div className="terms-modal-content" style={{paddingRight:'3vw', paddingLeft:'3vw'}}>
                    <ul>
                    <li className="modal-list-item">ההגרלה בפיקוח עו״ד החברה, עמית חלמיש</li>
                        <li className="modal-list-item">לטובת ההגרלה הוקצו 33 מכשירים בהתאם לרשימה באתר</li>
                        <li className="modal-list-item">ההשתתפות בהגרלה מוגבלת לרופאי שיניים וטכנאי שיניים בלבד</li>
                        <li className="modal-list-item">ניתן לסובב את הגלגל עד 3 ניסיונות ב 24 שעות</li>
                        <li className="modal-list-item">המשך נסיונות לסיבוב נוסף יתאפשר רק אחרי 24 שעות</li>
                        <li className="modal-list-item">אחוז ההנחה שהגרלת עם עצירת הגלגל מזכה אותך בהנחה כספית ממחירו הרשמי של המוצר.</li>
                        <li className="modal-list-item">כל הקודם זוכה!</li>
                        <li className="modal-list-item">שאלות ובקשה לפרטים נוספים ניתן לפנות למייל chakir@dentalchakir.co.il</li>
                    </ul>

                    <p style={{fontSize:'3vh', textAlign:'center', marginTop:'5vh'}}>
                        <a href={pdfFile} download style={{textDecoration:'underline', color: 'inherit'}}>
                            להורדת התקנון המלא לחצו כאן
                        </a>
                    </p>    
                    <p style={{fontSize:'3vh', textAlign:'center', marginTop:'5vh'}}>בהצלחה!</p>    
                </div>

                
                
                </div>
        </div>

    )
};

export default TermsModal;