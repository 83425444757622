import React, { useEffect, useState, useRef } from 'react';
import axios from '../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import WheelComponent from './WheelComp';
import shootingStars from '../assets/imgs/shootingStars.png';
import './styles/wheelSpin.css';
import RegPopup from './RegPopup';

// Function to get a cookie by name
function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

// Function to set a cookie
function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

const dummySegments = [
  '15%', '35%', '30%',
  '20%', '15%', '20%',
  '25%', '20%', '15%',
  '25%', '15%', '30%'
];
const dummySegmentColors = [
  "#FF6766",
  "#FFA07A",
  "#FFD700",
  "#ADFF2F",
  "#00FF7F",
];

const WheelSpin = () => {
  const location = useLocation();
  const history = useNavigate();
  const { product } = location.state || {};

  useEffect(() => {
    console.log('Effect ran. Product:', product);
    if (!product) {
      // Redirect to product selection page if no product is selected
      console.log('Redirecting to /products');
      history('/products');
    }
  }, []);

  const [segments, setSegments] = useState(dummySegments);
  const [segmentColors, setSegmentColors] = useState(dummySegmentColors);
  const [showRegistration, setShowRegistration] = useState(false);
  const [spinTheWheel, setSpinTheWheel] = useState(false);
  const [canWheelStop, setCanWheelStop] = useState(false);
  const [user, setUser] = useState('');
  const [userId, setUserId] = useState('');

  // Helper function to get wheel size
  function getWheelSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      return 125; // Smaller size for mobile devices
    } else {
      return 250; // Larger size for larger screens
    }
  }

  // Initialize wheelSize and durations
  const [wheelSize, setWheelSize] = useState(getWheelSize());
  const [wheelUpDuration, setWheelUpDuration] = useState(wheelSize < 200 ? 50 : 100);
  const [wheelDownDuration, setWheelDownDuration] = useState(wheelSize < 200 ? 50 : 100);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const updateWheelSize = () => {
      const newSize = getWheelSize();
      setWheelSize(newSize);

      if (newSize < 200) {
        // For smaller wheels, adjust durations accordingly
        setWheelUpDuration(50);
        setWheelDownDuration(50);
      } else {
        setWheelUpDuration(100);
        setWheelDownDuration(100);
      }
    };

    // Call the function on component mount and on window resize
    window.addEventListener('resize', updateWheelSize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', updateWheelSize);
  }, []);

  useEffect(() => {
    // Check if userId cookie is present
    const userId = getCookie('userId');

    if (!userId) {
      // If no userId, show registration popup
      // setShowRegistration(true);
    } else {
      setUserId(userId);
      axios.get(`/users/get-user/${userId}`)
        .then(response => {
          // Handle the response
          // if (response.data.user.prize !== null && response.data.user.prize !== undefined && response.data.user.prize !== '') {
          //   history('/winning', { state: { user: response.data.user } });
          // }
          if (response.data.user.spinCount.length >= 3) {
            history('/winning', { state: { user: response.data.user } });
          }
          setCanWheelStop(true);
        })
        .catch(error => {
          console.error(error);
          // remove the userId cookie
          setCookie('userId', '', 0);
          setUserId('');
          // Show registration popup
          setShowRegistration(true);
        });
    }
  }, [userId]);

  const userIdRef = useRef(userId);

  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);

  const onFinished = (winner) => {
    const currentUserId = userIdRef.current;

    // Send spin result to backend
    axios.post('/users/register-spin', { userId: currentUserId, result: winner })
      .then((response) => {
        history('/winning', { state: { winner: winner, user: response.data.user } });
      })
      .catch((error) => console.error(error));
  };

  const handlePopupClose = (newUserId) => {
    // Close the registration popup
    setShowRegistration(false);
    setUserId(newUserId);
    // Save the userId in the cookie
    setCookie('userId', newUserId, 7); // Expires in 7 days

    // Allow the wheel to stop
    // setCanWheelStop(true);
  };

  const handleClicked = () => {
    setClicked(true);
    if (!userId) {
      // If no userId, show registration popup
      setCanWheelStop(false);
      setShowRegistration(true);
    } else {
      setCanWheelStop(true);
    }
  };

  return (
    <div className="wheel-spin" style={{ display: 'flex', flexDirection: 'column' }}>

      {product && <h2 style={{ margin: 0, direction: 'rtl' }}>סובבו את הגלגל וזכו בהטבה ל{product.name}</h2>}
      {product && <button onClick={handleClicked} className="spin-button">סובבו</button>}
      <WheelComponent
        segments={segments}
        segColors={segmentColors}
        onFinished={(winner) => onFinished(winner)}
        primaryColor="#E8E9EB"
        contrastColor="white"
        buttonText="Spin"
        isOnlyOnce={false}
        size={wheelSize}
        upDuration={wheelUpDuration}
        downDuration={wheelDownDuration}
        spinTheWheel={spinTheWheel}
        resetSpin={() => setSpinTheWheel(false)}
        clicked={clicked}
        canWheelStop={canWheelStop}
      />
      <img src={shootingStars} alt="Shooting stars" className="shooting-stars" />
      {showRegistration && (
        <RegPopup
          product={product}
          closePopup={handlePopupClose}
        />
      )}
    </div>
  );
};

export default WheelSpin;
