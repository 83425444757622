import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/auth';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await login(username, password);
      // Store the token in local storage
      localStorage.setItem('token', response.data.token);
      navigate('/admin');
    } catch (err) {
      setError(err.response.data.message || 'Login failed.');
    }
  };

  return (
    <div className="admin-login">
      <h2>Admin Login</h2>
      {error && <p className="error">{error}</p>}
      <div style={{textAlign:'center'}}>
      <form onSubmit={handleLogin} style={{display:'flex', flexDirection:'column', width:'33vw', gap:'2vh', marginLeft:'34vw', marginTop:'12vh'}}>
        <input style={{textAlignLast:'left'}} type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
        <input style={{textAlignLast:'left'}}  type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <button type="submit">Login</button>
      </form>
      </div>
    </div>
  );
};

export default AdminLogin;
