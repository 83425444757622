import React, { useEffect, useState } from 'react';
import axios from '../../services/api';
import UserRow from './UserRow';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [contactedFilter, setContactedFilter] = useState('all');

  const fetchUsers = () => {
    const token = localStorage.getItem('token');
    let query = '';
    if (contactedFilter !== 'all') {
      query = `?contacted=${contactedFilter === 'contacted'}`;
    }
    axios.get(`/admin/users${query}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => setUsers(response.data))
      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchUsers();
  }, [contactedFilter]);

  const handleExport = () => {
    const token = localStorage.getItem('token');
    axios.get('/admin/users/export', { responseType: 'blob' },
      {
        headers: {
          'Authorization': `Bearer ${token}` // Include the token in the Authorization header
          }
          })
      .then(response => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', 'users.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.error(error));
  };

  return (
    <div className="users-list">
      <h2>Users</h2>
      <div className="filters">
        <label>Filter by Contacted Status:</label>
        <select value={contactedFilter} onChange={e => setContactedFilter(e.target.value)}>
          <option value="all">All</option>
          <option value="contacted">Contacted</option>
          <option value="not_contacted">Not Contacted</option>
        </select>
        <button onClick={handleExport}>Export to Excel</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Selected Product</th>
            <th>Prize</th>
            <th>Contacted</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <UserRow key={user._id} user={user} refreshUsers={fetchUsers} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;
