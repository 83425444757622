import React from 'react';
import axios from '../../services/api';



const UserRow = ({ user, refreshUsers }) => {
  const toggleContacted = () => {
    const token = localStorage.getItem('token');
    axios.put(`/admin/users/${user._id}/contacted`, { contacted: !user.contacted }, {
    headers: {
      'Authorization': `Bearer ${token}` // Include the token in the Authorization header
    },
    })
      .then(() => {
        refreshUsers();
      })
      .catch(error => console.error(error));
  };

  return (
    <tr>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.phone || 'N/A'}</td>
      <td>{user.selectedProduct ? user.selectedProduct.name : 'N/A'}</td>
      <td>{user.prize || 'N/A'}</td>
      <td>{user.contacted ? 'Yes' : 'No'}</td>
      <td>
        <button onClick={toggleContacted}>
          Mark as {user.contacted ? 'Not Contacted' : 'Contacted'}
        </button>
      </td>
    </tr>
  );
};

export default UserRow;
