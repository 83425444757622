import React, { useState } from 'react';
import axios from '../../services/api';

const ProductRow = ({ product, refreshProducts }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState({
    name: product.name,
    image: product.image,
    inventory: product.inventory,
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedProduct({
      name: product.name,
      image: product.image,
      inventory: product.inventory,
    });
  };

  const handleSave = () => {
    const token = localStorage.getItem('token');
    axios.put(`/admin/products/${product._id}`, editedProduct,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      })
      .then(() => {
        setIsEditing(false);
        refreshProducts();
      })
      .catch(error => console.error(error));
  };

  return (
    <tr>
      <td>
        {isEditing ? (
          <input
            type="text"
            value={editedProduct.name}
            onChange={e => setEditedProduct({ ...editedProduct, name: e.target.value })}
          />
        ) : (
          product.name
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="text"
            value={editedProduct.image}
            onChange={e => setEditedProduct({ ...editedProduct, image: e.target.value })}
          />
        ) : (
          <img src={product.image} alt={product.name} width="50" />
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="number"
            value={editedProduct.inventory}
            onChange={e => setEditedProduct({ ...editedProduct, inventory: e.target.value })}
          />
        ) : (
          product.inventory
        )}
      </td>
      <td>
        {isEditing ? (
          <>
            <button onClick={handleSave}>Save</button>{' '}
            <button onClick={handleCancel}>Cancel</button>
          </>
        ) : (
          <button onClick={handleEdit}>Edit</button>
        )}
      </td>
    </tr>
  );
};

export default ProductRow;
