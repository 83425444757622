import axios from 'axios';

const api = axios.create({
  baseURL: 'https://45-93-92-78.cloud-xip.com:4000/api', // Adjust the port if necessary
  withCredentials: true,
});



export default api;
