import React, { useEffect, useState } from 'react';
import { Link, Routes, Route, useNavigate, useMatch } from 'react-router-dom';
import UsersList from './UsersList';
import ProductsList from './ProductsList';
import { logout } from '../../services/auth';
import axios from '../../services/api';

const AdminPanel = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Retrieve the token from local storage
    const token = localStorage.getItem('token');
  
    // Check authentication status
    axios.get('/auth/admin/check-auth', {
      headers: {
        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
      }
    })
    .then(() => setIsAuthenticated(true))
    .catch(() => navigate('/admin/login'));
  }, [navigate]);

  const handleLogout = () => {
    logout().then(() => navigate('/admin/login'));
  };

  if (!isAuthenticated) return null;

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <button onClick={handleLogout}>Logout</button>
      <nav>
        <ul>
          <li><Link to="/admin/users">Users</Link></li>
          <li><Link to="/admin/products">Products</Link></li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<h3>Please select a section.</h3>} />
        <Route path="users" element={<UsersList />} />
        <Route path="products" element={<ProductsList />} />
      </Routes>
    </div>
  );
};

export default AdminPanel;
