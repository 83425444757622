import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './styles/introPage.css';
import wheelCompBG from '../assets/imgs/wheelCompBG.webp';
import logo from '../assets/imgs/logo.png';
import downArrows from '../assets/imgs/downArrows.png';
import TermsModal from './termsModal';

const IntroPage = () => {
  const [stage, setStage] = useState(0);
  const [showTerms, setShowTerms] = useState(false);
  const [wasTermsRead, setWasTermsRead] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return(
  <div className="intro-page">
    {showTerms && <TermsModal setShowTerms={setShowTerms} setWasTermsRead={setWasTermsRead}/>}
    <img src={wheelCompBG} className='wheelComp-background' alt="Wheel of Fortune" />
    {stage === 0 &&(
      <>
    <img src={logo} className='logo' alt="Logo" style={{width:'10vw', alignSelf:'center'}}/>
    <h1 className='intro-title' >תערוכת אידן בוטלה</h1>
    <h1  className='intro-title2'> החל עידן<br />ההגרלה</h1>
    <h1 style={{alignSelf:'center', fontSize:'6vh', fontWeight:'900', lineHeight:'1'}}> 
    הבאנו ציוד רב למכירות סוף שנה בתערוכת אידן 
    <br /> 
    אולם היא בוטלה ברגע האחרון עקב המצב הבטחוני
</h1>
    <h1 style={{alignSelf:'center',fontSize:'6vh', fontWeight:'900', color:'#1C9ED8',}}>?אז מה עושים עכשיו</h1>
    <div className='bouncing-arrow'>
    <img src={downArrows} className='down-arrows' alt="Down Arrows" style={{alignSelf:'center'}} onClick={() => setStage(1)}/>
    </div>
    </>
)}
 {stage === 1 &&(
      <>
      {screenWidth > 768 &&(
    <h1 className='intro-title2'> הגרלת גלגל המזל <br />
!עם הנחות ענקיות
    </h1>
)}
{screenWidth <= 768 &&(
  <>
  <h1 className='intro-title2'> הגרלת גלגל המזל <br />
    </h1>
    <h1 className='intro-title2'><span dir="rtl">עם הנחות ענקיות!</span></h1>
    </>
)}
    <h1 className='intro-title3' > 
    כל שעליכם לעשות זה לבחור את אחד המוצרים
    <br />
המשתתפים בהגרלה, להירשם
    <br />
!ולסובב את הגלגל
</h1>
{screenWidth > 768 &&(
<h1>יש לקרוא את <span style={{ textDecoration: 'underline', cursor: 'pointer', color:'blue' }} onClick={() => setShowTerms(true)}>התקנון</span> לפני ההשתתפות בהגרלה*</h1>
)}
{screenWidth <= 768 &&(
  <h1 dir="rtl" style={{ fontSize:'2vh'}}>*יש לקרוא את <span dir="rtl" style={{ textDecoration: 'underline', cursor: 'pointer', fontSize:'2vh', color:'blue' }} onClick={() => setShowTerms(true)}>התקנון</span> לפני ההשתתפות בהגרלה</h1>
)}
<div className='bouncing-arrow'>
<Link style={{display:'flex'}} to="/products" className={`start-button`}>
            <img src={downArrows} className='down-arrows' alt="Down Arrows" style={{ marginTop: '8vh' }} />
</Link>
</div>
    </>
)}
  </div>
  )
}

export default IntroPage;
