import React, { useEffect, useState } from 'react';
import axios from '../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import WheelComponent from './WheelComp';
import shootingStars from '../assets/imgs/shootingStars.png';
import winningFrame from '../assets/imgs/winningFrame.webp';
import './styles/winningPage.css';
import RegPopup from './RegPopup';
import logo from '../assets/imgs/logo.png';




const WinningPage = () => {
  const location = useLocation();
  const history = useNavigate();
  const { winner, user } = location.state || {};
  const [spinsDone, setSpinsDone] = useState(0);
  const [textLeft, setTextLeft] = useState('');
  const [spinAgainText, setSpinAgainText] = useState('');
  const [selectedPrize, setSelectedPrize] = useState('');
const [p1, setP1] = useState('');
const [p2, setP2] = useState('');
const [p3, setP3] = useState('');
const [screenWidth, setScreenWidth] = useState(window.innerWidth);

useEffect(() => {
    if (user) {
        setSpinsDone(user.spinCount.length);
        console.log(user.spinCount.length)
        if (user.spinCount.length >= 3) {
            setP1(user.spinCount[0].result);
            setP2(user.spinCount[1].result);
            setP3(user.spinCount[2].result);
        }
        if (user.spinCount.length === 2) {
            setP1(user.spinCount[0].result);
        setP2(user.spinCount[1].result);
        }
        if (user.spinCount.length === 1) {
            setP1(user.spinCount[0].result);    
        }
    }
    }, [user]);

    useEffect(() => {
        if (spinsDone === 2) {
            setTextLeft('נותר לך ניסיון אחד')
            setSpinAgainText('סובב שוב את הגלגל')
        }
        if (spinsDone === 1) {
            setTextLeft('נותרו לך 2 ניסיונות')
            setSpinAgainText('סובב שוב את הגלגל')
        }
        if (spinsDone === 0) {
            setTextLeft('נותרו לך 3 ניסיונות')
            setSpinAgainText('סובב שוב את הגלגל')
        }
        if (spinsDone >= 3) {
            setTextLeft('לא נותרו לך ניסיונות')
            setSpinAgainText('תוכל לנסות שוב בעוד 24 שעות')
        }
    }, [spinsDone]);


const handleSpinAgain = () => {
    history('/wheel-spin');
}


const handleSelectePrize = (prize) => {
axios.post('/users/select-prize', { user, prize })
.then((response) => {
    setSelectedPrize(prize);
})
.catch((error) => console.error(error));
}

  return (
    <div className="wheel-spin">
    <img src={winningFrame} alt="Winning frame" className="winning-frame" />
        
      <h1 className='winning-title' style={{marginTop:0, direction:'rtl'}}>זכית!</h1>

      {!selectedPrize && (
        <>
      <h2 className='winning-subtitle' style={{marginTop:0, direction:'rtl'}}>{textLeft}</h2>

        <button className='continue-button-winner' onClick={handleSpinAgain}>{spinAgainText}</button>
        <h2 className='winning-subtitle2' >או</h2>
        <div className='prize-container'>
        <h2 className='prize-cont-title'>המשך עם אחת הזכיות שלך</h2>
        {p1 && (
        <div className='prize-card'>
            <h3 className='prize-name' onClick={()=>handleSelectePrize(p1)}>{p1} <br/><span style={{fontSize:'2vh'}}>הנחה</span></h3>
        </div>
        )}
        {p2 && (
        <div className='prize-card'>
            <h3 className='prize-name' style={{left:screenWidth>780? '15vw':'30vw'}} onClick={()=>handleSelectePrize(p2)}>{p2}<br/><span style={{fontSize:'2vh'}}>הנחה</span></h3>
        </div>
        )}

        {p3 && (
        <div className='prize-card'>
        <h3 className='prize-name' style={{left:screenWidth>780? '28vw':'57vw'}} onClick={()=>handleSelectePrize(p3)}>{p3}<br/><span style={{fontSize:'2vh'}}>הנחה</span></h3>
        </div>
        )}

        {spinsDone === 3 && (
            <p className='bottom-pargraph'>ניתן לסובב שוב ולהשתתף בהגרלה רק בעוד 24 שעות
            שים לב: המלאי מוגבל וכל הקודם זוכה!
            </p>
            )}
        </div>
        </>
        )}

        {selectedPrize && (
        <>
        <div className='selected-prize-card'>
        <h3 className='selected-prize-name' style={{cursor:'default'}}>{selectedPrize} <br/><span style={{fontSize:'2vh'}}>הנחה</span></h3>
        </div>
        <h2 className='well-be-in-touch'>אנו ניצור איתך קשר בהקדם בהתאם לפרטים שהשארת</h2>
        <p className='well-be-in-touch-2'>ניתן לסובב שוב ולהשתתף בהגרלה רק בעוד 24 שעות<br />
שים לב: המלאי מוגבל וכל הקודם זוכה!
</p>
<img className='bottom-logo' src={logo} alt="Dental Check" />
        </>
        )}
    </div>
  );
};

export default WinningPage;
