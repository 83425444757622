import React, { useEffect, useState } from 'react';
import axios from '../../services/api';
import ProductRow from './ProductRow';

const ProductsList = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = () => {
    const token = localStorage.getItem('token');
    axios.get('/admin/products', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => setProducts(response.data))
      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="products-list">
      <h2>Products</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Image</th>
            <th>Inventory</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <ProductRow key={product._id} product={product} refreshProducts={fetchProducts} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsList;
