import React, { useEffect, useState } from 'react';
import axios from '../services/api';
import { useNavigate } from 'react-router-dom';

import wheelCompBG from '../assets/imgs/wheelCompBG.webp';
import './styles/productSelection.css';
import dummyProduct from '../assets/imgs/dummyProduct.png';


// Function to get a cookie by name
function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}

// Function to set a cookie
function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}


const ProductSelection = () => {

  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
const [userId, setUserId] = useState('');
const [user, setUser] = useState(null);
const [loading, setLoading] = useState(true); // New loading state
  


useEffect(() => {
  axios.get('/products', { withCredentials: true })
    .then((response) =>  {setProducts(response.data); setLoading(false)})
    
    .catch((error) => {console.error(error); setLoading(false)});
}, []);


  useEffect(() => {
    // Check if userId cookie is present
    const userId = getCookie('userId');    
    if (!userId) {
    } else {
      setUserId(userId);
      axios.get(`/users/get-user/${userId}`)
      .then(response => {
        // Handle the response
        if (response.data.user.selectedProduct !== null && response.data.user.selectedProduct !== undefined && response.data.user.selectedProduct !== '') {
          handleProductClick(response.data.user.selectedProduct);
          // history('/wheel-spin');
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
    }
  }, []);



  

  const handleProductClick = (product) => {
    if (product.inventory === 0) return;
    setSelectedProduct(product);
    // setShowRegistration(true);
    // enter route to wheel spin
    history('/wheel-spin', { state: { product: product } });


  };

  const handleRadioChange = (product) => {
    setSelectedProduct(product);
  };


  return (
    <div className="product-selection">
      <img src={wheelCompBG} className='wheelComp-background' alt="Wheel of Fortune" />
      <h2>בחרו מוצר להגרלה:</h2>
      {loading ? ( // Conditional rendering based on loading state
        <div className="loader"></div> // Visual loader
      ) : (
        <div className="products-list">
          {products.map((product) => (
            <div key={product._id} className="product-card" onClick={() => handleProductClick(product)}>
              <div className={product.inventory === 0 ? "product-img-container-disabled" : "product-img-container"}>
                <input
                  type="radio"
                  name="productSelect"
                  value={product._id}
                  onChange={() => handleRadioChange(product)}
                  checked={selectedProduct?._id === product._id}
                  className="product-radio"
                  disabled={product.inventory === 0}
                />
                <img style={{ height: '12vh' }} src={product.image} alt={product.name} />
                <p className="inventory" style={{ fontSize: '3vh', color: 'white' }}> {product.inventory} במלאי</p>
              </div>
              <h3>{product.name}</h3>
            </div>
          ))}
        </div>
      )}
      <p className='limited-inventory-p' style={{ fontSize: '5vh' }}>שים לב: המלאי מוגבל וכל הקודם זוכה!</p>
    </div>
  );
};

export default ProductSelection;
