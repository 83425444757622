import React, { useState } from 'react';
import axios from '../services/api';
import { useNavigate } from 'react-router-dom';
import './styles/regPopup.css';
import TermsModal from './termsModal';

const RegPopup = ({ product, closePopup, userId }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [terms, setTerms] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const history =  useNavigate();

  const handleRegistration = () => {
    axios.post('/users/register', {
      name,
      phone,
      email,
      selectedProduct: product._id,
    })
      .then((response) => {
        console.log(response);
        const userId = response.data.user._id;
        closePopup(userId);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className='registration-popup-shadow'>
    <div className="registration-popup">
      {showTerms && <TermsModal setShowTerms={setShowTerms} />}
        <h2>בזמן שהגלגל מסתובב</h2>
      <div className="popup-content" style={{display:'flex', gap:'1vh', flexDirection:'column'}}>
        <div className="input-group">
        <label style={{width:'8vw'}} htmlFor="name">שם מלא</label>
        <input
          type="text"
          // placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          // style={{width:'32vw'}}
          className="text-input"
        />
        </div>

        <div className="input-group">
        <label style={{width:'8vw'}} htmlFor="name">מספר טלפון</label>
        <input
          type="phone"          
          value={phone}
          onChange={(e) => {
            const value = e.target.value;
            // Allow only numbers and limit to 10 digits
            if (/^\d{0,10}$/.test(value)) {
              setPhone(value);
            }
          }}
          // style={{width:'32vw'}}
          className="text-input"
        />
        </div>

        <div className="input-group">
        <label style={{width:'8vw'}} htmlFor="email">אימייל</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => {
            const value = e.target.value;
            // Basic email validation regex
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value) && value !== '') {
              alert('אנא הכנס כתובת מייל תקינה');
            }
          }}
          // style={{width:'32vw'}}
          className="text-input"
        />
        </div>

        {/* <div className="input-group">
        <input type="checkbox" onChange={()=>setMarketing(!marketing)} id="marketing" name="marketing" value="marketing" />
        <p style={{margin:'0'}}>אני מאשר/ת קבלת דיוורים/sms/הודעות וואטסאפ/מייל מחברת דנטל צ׳אקיר</p>
        </div> */}

        <div className="input-group">
        <input type="checkbox" onChange={()=>setTerms(!terms)} id="terms" name="terms" value="terms" />
        <p style={{margin:'0'}}>אני מאשר/ת שקראתי את <span dir="rtl" style={{ textDecoration: 'underline', cursor: 'pointer', fontSize:'2vh', color:'blue' }} onClick={() => setShowTerms(true)}>התקנון</span>  והנהלים, ואני מסכימ/ה לכל תנאיו.</p>
        </div>


        <button className={!terms?'continue-button-dis':'continue-button'} disabled={!terms} onClick={handleRegistration}>שלח והראה לי במה זכיתי</button>
        {/* <button onClick={closePopup}>Cancel</button> */}
      </div>
    </div>
    </div>
  );
};

export default RegPopup;
